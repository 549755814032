<template>
  <div class="agreement" v-html="agreement"></div>
</template>

<script>
import { connectState } from '@/utils/wyUtil';

export default {
  computed: {
    ...connectState('goods', ['agreement']),
    protocol() {
      let val;
      const { catalog } = this.$route.query;
      switch (catalog) {
        case '4':
        case '5':
        case '14':
        case '15':
          val = 'ZZPurchaseAgreement';
          break;
        case '2':
        case '3':
        case '21':
          val = 'KTPurchaseAgreement';
          break;
        case '0':
          val = 'memberServerPolicy';
          break;
        default:
          val = 'ALLPurchaseAgreement';
          break;
      }
      return val;
    },
  },
  created() {
    this.$store.dispatch('goods/getAgreement', this.protocol);
  },
};
</script>

<style lang="scss" scoped>
.agreement{
  width: 1200px;
  margin: 20px auto 0;
  background-color: #fff;
  padding: 15px 20px;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
